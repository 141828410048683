window.addEventListener('DOMContentLoaded', () => {
    setValidateInputFromForm();
});

// Validación para el formulario dinamico
function setValidateInputFromForm()
{
    const validate = new window.JustValidate('#section-info-user-form-dynamic-schedule-appointment',{
        validateBeforeSubmitting: true,
    });

    const arrayValidations = {
        name: { id: '#name_form_dynamic_schedule_appointment', rules: [
            { rule: 'required' },
            { rule: 'maxLength', value: 45, }
        ]},
        email: { id: '#email_form_dynamic_schedule_appointment', rules: [
            { rule: 'required' },
            { rule: 'email' },
            { rule: 'maxLength', value: 45, }
        ]},
        phone: { id: '#phone_form_dynamic_schedule_appointment', rules: [
            { rule: 'required' },
            { rule: 'minLength', value: 9, },
            { rule: 'maxLength', value: 14, },
            { rule: 'customRegexp', value: /^(\+[0-9]{1,2})?[0-9]{9,11}$/, }
        ]},
        description: { id: '#description_form_dynamic_schedule_appointment', rules: [
            { rule: 'required' },
            { rule: 'maxLength', value: 300, }
        ]},
        title: { id: '#title_form_schedule', rules: [
            { rule: 'required' },
            { rule: 'maxLength', value: 45, }
        ]},
        titleOption: { id: '#inputFormTitleOptional_dynamic_schedule_appointment', rules: [
            { rule: 'maxLength', value: 45, },
            {
                validator: ( value, fields ) => {
                    if( 
                        fields['#title_form_schedule'] &&
                        fields['#title_form_schedule'].elem &&
                        fields['#title_form_schedule'].elem.value == "Other"
                    )
                    {
                        if( value == null || value == "" )
                        return false;
                    }

                    return true;
                },
                errorMessage: 'The Channel is required',
            }
        ]}
    }

    validate.addField( arrayValidations.name.id, arrayValidations.name.rules )
    .addField( arrayValidations.email.id, arrayValidations.email.rules )
    .addField( arrayValidations.phone.id, arrayValidations.phone.rules )
    .addField( arrayValidations.description.id, arrayValidations.description.rules )
    .addField( arrayValidations.title.id, arrayValidations.title.rules )
    .addField( arrayValidations.titleOption.id, arrayValidations.titleOption.rules );
}

/**
 * Elimina el alert de emitido por el form.
 **/
function removeAlertStatusFromFormResult( option = 1 )
{
    // 1 => Status, 2 => Errors validations

    const idAlertElement = option == 1 ? "alert_success_form_schedule_appointment" : "alert_error_form_schedule_appointment";

    const alertElement = document.getElementById( idAlertElement );

    if( !alertElement ) return;

    alertElement.remove();
}

// Recibe Evento de Livewire para restablecer el Formulario Dinamico.
window.addEventListener('resetDynamicFormScheduleAppointmet', () => {
    // Emite un evento para el componente de Alpine JS.
    window.document.dispatchEvent( 
        new CustomEvent('restartformscheduleappointment', { bubbles: true })
    );
});

// Recibe Evento de Livewire para mostrar la siguiente vista del Formulario Dinamico.
window.addEventListener('continueDynamicFormScheduleAppointmet', () => {
    // Emite un evento para el componente de Alpine JS.
    window.document.dispatchEvent( 
        new CustomEvent('continueformscheduleappointment', { bubbles: true })
    );
});

window.addEventListener('alertMessagesDynamicFormScheduleAppointmet', ( event ) => {
    // console.log("evento js para el alert del alpinejs", event, event.detail.messages, JSON.parse(event.detail.messages));
    
    const message = event.detail.typeAlert == 1 ? event.detail.messages : JSON.parse(event.detail.messages);
    const nameTypeAlert = event.detail.typeAlert == 1 ? 'alertmessagescheduleappointment' : 'alerterrorscheduleappointment';

    // Emite un evento para el componente de Alpine JS.
    window.document.dispatchEvent(
        new CustomEvent(nameTypeAlert, { bubbles: true, detail: { messages: message } })
    );
});